#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0;
  z-index: 999;

  .navbar {
    display: flex;

    &-logo {

      &-wrap {
        margin: auto;
      }

      img {
        height: 50px;

        @include media-desktop() {
          height: 60px;
        }
      }
    }
  }
}

#loading {
  background: #000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {

      &-logo {
        margin: auto;

        img {
          height: 80px;
  
          @include media-desktop() {
            height: 100px;
          }
        }
      }
  }
}

#footer {
  @include font-size(1.1rem);
  padding-bottom: 30px;
  
  @include media-desktop() {
    padding-bottom: $margin-section / 2;
  }

  img {
    border-radius: 0;
  }

  a {
    color: $color-primary;
  }

  .footer {

    &-wrap {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    &-address {
      a {
        @include text-underline();
      }
    }

    &-socmed {

      a {
        margin-right: 1rem;

        img {
          transition: transform 0.3s ease;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {

          img {
            transform: scale(1.1);
          }
        }
      }
    }

  }
}