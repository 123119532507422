#intro {
  margin-top: $margin-section / 2.5;
  margin-bottom: $margin-section / 2.5;
  position: relative;
  width: 100%;
  height: 100vh;


  @include media-desktop() {
    margin-bottom: $margin-section;
  }

  .container {
    position: relative;
  }

  .intro {

    &-subtitle {
      padding-top: 2rem;

      @include media-desktop() {
        margin-left: 12px;
      }
    }

    &-title {
      @include font-size(7vh);

      .word {
        perspective: 1000px;
        perspective-origin: -150% 50%;
      }

      @include media-desktop() {
        @include font-size(9vw);
      }
    }

    &-img {
      position: absolute;
      object-fit: cover;
      right: 0;
      width: 100%;
      bottom: 13vh;
      z-index: -1;

      @include media-desktop() {
        width: 60%;
      bottom: 15vh;
      }
    }
  }
}

#about {
  margin-top: $margin-section / 2.5;

  @include media-desktop() {
    margin-top: $margin-section;
  }

  .about {

    &-wrap {
      display: grid;
      place-items: center;
      text-align: center;
      position: relative;
    }

    &-title {
      position: relative;
      margin-top: 1rem;
      margin-bottom: 8rem;

      .word {
        perspective: 1000px;
        perspective-origin: -150% 50%;
      }

      h2 {
        @include font-subheading($size: 3rem);
        position: absolute;
        bottom: -5%;
        right: 15%;
        color: $color-primary;
        font-family: $font-justsig;
        text-transform: none;
      }

      h3 {
        @include font-heading();
      }
    }

    &-img {
      position: absolute;
      z-index: -1;
      width: 80%;
      opacity: 0.3;
      right: 5;
      bottom: -120%;

      @include media-desktop() {
        opacity: 0.5;
        width: 27%;
        right: 18%;
        bottom: -100%;
      }
    }

    &-desc {
      max-width: 550px;
      margin: 0 auto;

      p {
        text-align: justify;
  
        @include media-desktop() {
          text-align: left;
        }
      }
    }
  }
}

#services {

  .container {
    position: relative;
  }

  .services {

    &-title {
      white-space: nowrap;
      @include font-size(19vw);

      h2 {
        margin-top: 1rem;

        .word {
          perspective: 1000px;
          perspective-origin: -150% 50%;
        }
      }
    }

    &-list {
      margin-top: 11rem;

      &-item {
        padding: 0.5rem 0;
        border-bottom: 1px solid $color-primary;

        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }

      }
    }

    &-badge {
      position: absolute;
      right: 15px;
      bottom: 0;
      max-width: 100px;

      @include media-desktop() {
        right: 0;
        max-width: none;
      }
    }
  }
}


#contact {

  .contact {

    &-title {
      margin: auto;
      width: 100%;
      transition: transform 0.3s ease;

      &:hover {
        transform: scaleX(1.02)
      }

      &-wrap {
        display: flex;
      }

      small {
        margin-bottom: 1rem;
        display: block;
      }

      .word {
        perspective: 1000px;
        perspective-origin: -150% 50%;
      }

      &-1 {
        text-align: left;
      }

      &-2 {
        text-align: center;
        margin-left: 15%;
      }

      &-3 {
        text-align: right;
      }
    }

    &-content {
      height: 30vh;
      width: 100%;
    }
  }
}